import React, {Fragment, useState} from 'react';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useScrollTrigger
} from "@material-ui/core";
import clsx from "clsx";
import FemaleLab from "../../assets/female_lab.png";
import {useHistory, useLocation} from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {makeStyles} from "@material-ui/core/styles";
import UpperLeftHeader from "../../assets/upper_left_header.png";
import Routes from "./Routes";

const useStyles = makeStyles((theme) => {
  const drawerWidth = 200;
  const drawerCloseWidth = theme.spacing(7) + 1;
  const drawerCloseBrkPtsWidthSm = theme.spacing(9) + 1;
  return {
    appBar: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: "transparent",
      width: `calc(100% - ${drawerCloseWidth}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerCloseBrkPtsWidthSm}px)`,
      },
      color: 'black',
    },
    appBarElevate: {
      backgroundColor: 'white'
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerCloseWidth,
      [theme.breakpoints.up('sm')]: {
        width: drawerCloseBrkPtsWidthSm,
      },
    },
    toolbarHeader: {
      backgroundImage: `url(${UpperLeftHeader})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    profile: {
      padding: "10px",
      paddingRight: theme.spacing(3),
      backgroundColor: "white",
      height: "64px",
    },
  };
});

export default function DrawerAndTopBar({drawerWidth, window}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  const routes = Routes();
  const routesArray = Array.from(Object.values(routes))
  const pathTitleMap = new Map(routesArray.map(route => [route.path, route.title]))
  return (
    <Fragment>
      <AppBar
        position="fixed"
        elevation={trigger ? 4 : 0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
          [classes.appBarElevate]: trigger,
        })}
      >
        <Box display="flex">
          <Box flexGrow={1}>
            <Toolbar>
              <Typography variant="h5" noWrap>{pathTitleMap.get(location.pathname)}</Typography>
            </Toolbar>
          </Box>
          <Box className={clsx(classes.toolbar, classes.profile)}>
            <img src={FemaleLab} height="90%" alt="Doctor Logo"/>
            <Typography variant="h6" noWrap>
              Dr. Mai Sara
            </Typography>
          </Box>
        </Box>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        <div className={clsx(classes.toolbar, classes.toolbarHeader)}>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            {!drawerOpen ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
          </IconButton>
        </div>
        <Divider/>
        <List>
          {
            routesArray.filter(o => !!o.menuIcon).map((route) => {
              return <ListItem key={route.path} button selected={location.pathname === route.path} onClick={() => {
                history.push(route.path)
              }}>
                <ListItemIcon>{route.menuIcon}</ListItemIcon>
                <ListItemText primary={route.title}/>
              </ListItem>
            })
          }
        </List>
      </Drawer>
    </Fragment>
  )
}