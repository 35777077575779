import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Toolbar} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import DailyAppointments from "./DailyAppointments";
import DrawerAndTopBar from "./DrawerAndTopBar";
import Routes from "./Routes";

const useStyles = makeStyles((theme) => {
  const mainContentPadding = theme.spacing(3);

  return {
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    content: {
      flexGrow: 1,
      backgroundColor: '#D6FAF3',
      minHeight: "100vh",
    },
    mainPanel: {
      display: "flex",
      flexDirection: "row"
    },
    pagePanel: {
      flexGrow: 1,
      padding: mainContentPadding,
    },
    appointmentPanel: {
      width: "300px",
      paddingTop: mainContentPadding,
      "& > .MuiPaper-root": {
        marginBottom: "10px"
      }
    }
  }
});

export default function Admin(props) {
  // let {path, url} = useRouteMatch();
  let {path} = useRouteMatch();
  const classes = useStyles();
  const routes = Routes();
  const routesArray = Array.from(Object.values(routes));
  return (
    <div className={classes.root}>
      <DrawerAndTopBar classes={classes} {...props} />
      <main className={classes.content}>
        <Toolbar/>
        <div className={classes.mainPanel}>
          <div className={classes.pagePanel}>
            <Switch>
              {
                // Do not use exact. Otherwise, the subroutes like /admin/patients won't work
                routesArray.map((route) => <Route key={route.path} path={route.path}>{route.component}</Route>)
              }
              <Redirect from={path} to={`${path}/dashboard`}/>
            </Switch>
          </div>
          <div className={classes.appointmentPanel}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DailyAppointments/>
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </main>
    </div>
  );
}