import {withStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";

const ActionButton = withStyles((theme) => ({
  root: {
    minWidth: "180px",
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    }
  },
}))(Button);

export default ActionButton;