import React from 'react';
import './App.css';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Login from "./components/Login";
import Admin from "./components/Admin/Admin";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <Login/>
        </Route>
        <Route path="/admin">
          <Admin/>
        </Route>
        <Redirect from="/" to="/login"/>
      </Switch>
    </Router>
  );
}

// export default withStyles(styles, {withTheme: true})(App);
