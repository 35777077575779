import {withStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";

const CircularButton = withStyles((theme) => ({
  root: {
    textAlign: "center",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "50%",
    width: "36px",
    height: "36px",
    minWidth: "inherit",
  },
}))(Button);

export default CircularButton;