import React from 'react';
import {Box, Button, Checkbox, FormControlLabel, makeStyles, Typography} from "@material-ui/core";
import FilledTextField from "./hoc/FilledTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    minHeight: "100vh",
  },
  leftPanel: {
    backgroundColor: "#ED733B",
    textAlign: "center",
    "& div > *": {
      marginBottom: "2px"
    },
    "& h3": {
      color: "#F8F8F8",
      marginBottom: "15px"
    }
  },
  actionBtn: {
    marginTop: "15px",
    minWidth: "150px",
  },
  inputField: {
    "& input": {
      backgroundColor: "#FFF"
    }
  }
}));

const Login = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center" className={classes.leftPanel}>
        <Box display="flex" flexDirection="column" minWidth="30%">
          <Typography variant="h3">Welcome</Typography>
          <FilledTextField required id="username" label="Username" type="text" className={classes.inputField}/>
          <FilledTextField required id="password" label="Password" type="password" className={classes.inputField}/>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box flexGrow={1}>
              <FormControlLabel control={<Checkbox name="checkedC" color="primary"/>} label="Remember"/>
            </Box>
            <Box>
              <Button>
                <Typography variant="body1">
                  Forgot Password?
                </Typography>
              </Button>
            </Box>
          </Box>
          <div>
            <Button className={classes.actionBtn} variant="contained" color="primary">SUBMIT</Button>
          </div>
        </Box>
      </Box>
      <Box flexGrow={1}>

      </Box>
    </div>
  )
}

export default Login;