import React, {useState} from 'react';
import {Calendar} from '@material-ui/pickers'
import {Box, Paper, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {toHHmm} from "../../utils/time";

const useStyles = makeStyles(theme => {
  const tableBorder = "1px solid rgba(224, 224, 224, 1)"
  return {
    appointmentPanel: {
      "& ::-webkit-scrollbar": { // See https://stackoverflow.com/questions/18317634/force-visible-scrollbar-in-firefox-on-mac-os-x
        "-webkit-appearance": "none",
        width: "7px"
      },
      "& ::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        backgroundColor: "rgba(0, 0, 0, .5)",
        boxShadow: "0 0 1px rgba(255, 255, 255, .5)",
      },
    },
    appointmentTable: {
      borderTop: tableBorder,
      "& > tbody > tr > td:first-child": {
        borderRight: tableBorder,
      },
    }
  }
});

const data = [
  {
    name: "Trixy Sara",
    startTime: 8,
    endTime: 10, // Exclusive
  },
  {
    name: "Cloud Xavier Ramirez",
    startTime: 10,
    endTime: 11,
  },
  {
    name: "VeryVeryVeryVeryVeryVery Long Name",
    startTime: 12,
    endTime: 13,
  },
  {
    name: "Natalie Laurence Westley",
    startTime: 16,
    endTime: 20,
  },
]

const HourlyAppointmentsTable = ({classes}) => {
  const appointmentMap = new Map();
  for (let i = 0; i < data.length; i++) {
    const appointment = data[i];
    for (let j = appointment.startTime; j < appointment.endTime; j++) {
      appointmentMap.set(j, appointment);
    }
  }
  const rows = [];
  let lastAppointment = {};
  for (let h = 8; h <= 22; h++) {
    let appointment = appointmentMap.get(h);
    if (!appointment) {
      appointment = {name: "", startTime: h, endTime: h + 1}
    }
    rows.push(
      <TableRow key={h}>
        <TableCell>{toHHmm(h)}</TableCell>
        {
          (!appointment.name || appointment.name !== lastAppointment.name) &&
          <TableCell rowSpan={appointment.endTime - appointment.startTime}>{appointment.name}</TableCell>
        }
      </TableRow>
    )
    lastAppointment = appointment;
  }
  return (
    <Table className={classes.appointmentTable}>
      <TableBody>
        {rows}
      </TableBody>
    </Table>
  )
};

export default function DailyAppointments() {
  const [selectedDate, handleDateChange] = useState(new Date());
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <Box component={Paper} elevation={1} mb={1}>
        <Calendar
          date={selectedDate}
          onChange={handleDateChange}
        />
      </Box>
      <Box component={Paper} elevation={1} p={0} flexGrow={1} overflow="scroll" className={classes.appointmentPanel}>
        <Box p={1}><Typography variant="h6" component="p">Time</Typography></Box>
        <HourlyAppointmentsTable classes={classes}/>
      </Box>
    </Box>
  )
}