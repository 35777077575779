import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Avatar, Box, Card, CardContent, CardHeader, IconButton, Typography,} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Skeleton from "@material-ui/lab/Skeleton";
import Female from './../../assets/female.png';

const useStyles = props => makeStyles(theme =>
  createStyles({
    root: {
      width: "100%"
    },
    card: {
      display: "flex",
      flexDirection: "column",
      padding: "16px 12px",
      height: (props.height - 10) + "px",
      "& .MuiCardHeader-root": {
        padding: "0 10px 0 10px",
      },
      "& .MuiCardHeader-avatar": {
        marginRight: "4px",
      },
      "& .MuiCardContent-root::-webkit-scrollbar": { // See https://stackoverflow.com/questions/18317634/force-visible-scrollbar-in-firefox-on-mac-os-x
        "-webkit-appearance": "none",
        width: "7px"
      },
      "& .MuiCardContent-root::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        backgroundColor: "rgba(0, 0, 0, .5)",
        boxShadow: "0 0 1px rgba(255, 255, 255, .5)",
      },
      "& .MuiCardContent-root": {
        // padding: "10px 10px 0 10px",
        padding: "10px",
        // height: (props.height - 200) + "px",
        overflow: "scroll",
        flexGrow: 1,
      },
      "& .MuiCardContent-root table > tbody > tr > td:first-child": {
        verticalAlign: "top",
        paddingRight: "10px",
        fontWeight: "bold",
      },
      // "& .MuiCardActions-root": {
      //   padding: "10px"
      // }
    },
    avatar: {
      backgroundColor: red[500]
    },
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  })
);

const DashboardPatientCard = ({index, style, data}) => {
  const classes = useStyles(style)();
  const loading = data[index] === null;

  return (
    <Box style={style} className={classes.root}>
      <Card className={classes.card} elevation={2}>
        <CardHeader
          avatar={
            loading ? (
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
            ) : (
              // <IconButton size="medium">
              //   <img src={FemaleLab} height="20%"/>
              // </IconButton>
              <IconButton>
                <Avatar alt="Remy Sharp" src={Female} className={classes.large}/>
              </IconButton>
            )
          }
          action={
            loading ? null : (
              <IconButton aria-label="settings">
                <MoreVertIcon/>
              </IconButton>
            )
          }
          title={
            loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{marginBottom: 6}}
              />
            ) : (
              "Cloud Xavier Ramirez"
            )
          }
          subheader={
            loading ? (
              <Skeleton animation="wave" height={10} width="40%"/>
            ) : (
              <React.Fragment>
                <div>Ward 2, Larrobis Compound, Poblacion, Minglanilla Cebu</div>
                <div>+639171234567</div>
              </React.Fragment>
            )
          }
        />
        <CardContent>
          {loading ? (
            <>
              <Skeleton
                animation="wave"
                height={10}
                style={{marginBottom: 6}}
              />
              <Skeleton animation="wave" height={10} width="80%"/>
            </>
          ) : (
            <table>
              <tbody>
              <tr>
                <td><Typography variant="body2" color="textSecondary" component="p">Branch:</Typography></td>
                <td>Tabunok, Talisay, Cebu</td>
              </tr>
              <tr>
                <td><Typography variant="body2" color="textSecondary" component="p">Treatment:</Typography></td>
                <td>{index === 1 ?
                  <div>{data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} {data[index]} </div> : data[index]} </td>
              </tr>
              </tbody>
            </table>
          )}
        </CardContent>
        {/*<CardActions disableSpacing>*/}
        {/*  {loading ? null : (*/}
        {/*    <>*/}
        {/*      <IconButton aria-label="add to favorites">*/}
        {/*        <FavoriteIcon/>*/}
        {/*      </IconButton>*/}
        {/*      <IconButton aria-label="share">*/}
        {/*        <ShareIcon/>*/}
        {/*      </IconButton>*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*</CardActions>*/}
      </Card>
    </Box>
  );
};

export default React.memo(DashboardPatientCard);
