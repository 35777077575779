import React, {Fragment} from 'react';
import {Box, FormControl, MenuItem, Select, Typography} from "@material-ui/core";
import BootstrapInput from './../hoc/BootstrapInput';
import DashboardPatientList from "./DashboardPatientList";
import ActionButton from "../hoc/ActionButton";
import {useHistory} from "react-router-dom";
import Routes from "./Routes";

const branches = [
  {id: "tabunok", name: "Tabunok, Talisay City"},
  {id: "robinsons", name: "Robinsons, Cebu City"},
]

export default function Dashboard() {
  const history = useHistory();
  const routes = Routes();
  return (
    <Fragment>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant={"h6"}>Current Patient</Typography>
        <Box flexGrow={1} pl={5}>
          <FormControl>
            <Select
              labelId="branch-select-label"
              id="branch-select"
              value={branches[0].id}
              input={<BootstrapInput/>}
            >
              {branches.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <ActionButton variant="contained" color="primary" onClick={() => history.push(routes.bookAppointment.path) }>Book Appointment</ActionButton>
      </Box>
      <DashboardPatientList/>
    </Fragment>
  )
}