import React, {Fragment, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {getSundayStartAndEndOfWeek} from '../../utils/date';
import {toHHmm} from '../../utils/time';
import CircularButton from "../hoc/CircularButton";
import clsx from "clsx";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";

const useStyles = makeStyles((theme) => {
  const borderColor = theme.palette.grey["300"];
  const border = `1px solid ${borderColor}`;
  return {
    table: {
      '& > tbody > tr > td': {
        padding: 0,
        border: 0,
        borderRight: border,
      },
      '& > tbody > tr:nth-child(4n + 1) > td:first-child': {
        borderBottom: border,
      },
      '& > tbody > tr:nth-child(4n) > td': {
        borderBottom: border,
      },
      '& > tbody > tr > td > div': {
        fontSize: "8px"
      },
      '& > tbody > tr > td.MuiTableCell-sizeSmall:last-child': {
        padding: 0,
      },
    },
    today: {
      backgroundColor: theme.palette.primary.main
    },
    heading: {
      '& > div': {
        display: 'flex',
        paddingBottom: theme.spacing(1)
      },
      '& > div:first-child > button': {
        padding: theme.spacing(1)
      },
      '& > div:first-child > h5': {
        marginLeft: theme.spacing(1),
        color: theme.palette.grey["800"]
      },
    },
    formControl: {
      minWidth: 120,
    },
    booked: {
      backgroundColor: theme.palette.info.main
    },
  }
});

const dayOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

const {sunday} = getSundayStartAndEndOfWeek()
const currentMonth = sunday.getMonth();
const firstDate = sunday.getDate();
const data = [
  {
    month: currentMonth,
    date: firstDate,
    startHour: 8,
    startMin: 15,
    endHour: 9, // Exclusive
    endMin: 30, // Exclusive
  },
  {
    month: currentMonth,
    date: firstDate,
    startHour: 10,
    startMin: 0,
    endHour: 12,
    endMin: 15,
  },
  {
    month: currentMonth,
    date: firstDate,
    startHour: 13,
    startMin: 0,
    endHour: 15,
    endMin: 0, // Exclusive
  },
  {
    month: currentMonth,
    date: firstDate + 1,
    startHour: 12,
    startMin: 0,
    endHour: 13,
    endMin: 45,
  },
  {
    month: currentMonth,
    date: firstDate + 4,
    startHour: 11,
    startMin: 30,
    endHour: 13,
    endMin: 0,
  },
  {
    month: currentMonth,
    date: firstDate + 4,
    startHour: 15,
    startMin: 15,
    endHour: 15,
    endMin: 30,
  },
]

const toMins = (hour, mins) => hour * 60 + mins;

function Schedule({shiftHourStart = 8, shiftHourEnd = 19, initialData, startOfTheWeekDate, classes}) {
  const [bookings, setBookings] = useState(initialData.map(o => {
    return {...o}
  }))
  const daysInAWeek = 7;

  const results = [];

  const getBooking = (day, hour, mins) => {
    const hrmins = toMins(hour, mins)
    for (let i = 0; i < bookings.length; i++) {
      const booking = bookings[i];
      if (
        booking.date === day
        && hrmins >= toMins(booking.startHour, booking.startMin)
        && hrmins < toMins(booking.endHour, booking.endMin)
      ) {
        return booking;
      }
    }
    return null;
  }
  const createCells = (rowInAnHourIdx, hour) => {
    const mins = rowInAnHourIdx * 15;
    const cells = [];
    for (let dayInAWeek = 0; dayInAWeek < daysInAWeek; dayInAWeek++) {
      let date = dayInAWeek + startOfTheWeekDate;
      const booking = getBooking(date, hour, mins);
      if (booking) {
        if (booking.isPlotted) {
          continue;
        }
        booking.isPlotted = true;
        const rowSpan = (toMins(booking.endHour, booking.endMin) - toMins(booking.startHour, booking.startMin)) / 15;
        cells.push(
          <TableCell key={`cell-${hour}-${rowInAnHourIdx}-${date}`} align="center" rowSpan={rowSpan}
                     className={classes.booked}>
            <div>&nbsp;</div>
          </TableCell>
        )
      } else {
        cells.push(
          <TableCell key={`cell-${hour}-${rowInAnHourIdx}-${date}`} align="center">
            <div>&nbsp;</div>
          </TableCell>
        )
      }
    }
    return cells;
  }

  for (let hour = shiftHourStart; hour <= shiftHourEnd; hour++) {
    let rowInAnHourIdx = 0;
    results.push(
      <TableRow key={`row-${hour}-${rowInAnHourIdx}`}>
        <Fragment>
          <TableCell rowSpan={4} align="center">{toHHmm(hour)}</TableCell>
          {createCells(rowInAnHourIdx, hour)}
        </Fragment>
      </TableRow>
    )
    rowInAnHourIdx++;
    for (; rowInAnHourIdx < 4; rowInAnHourIdx++) {
      const cells = createCells(rowInAnHourIdx, hour);
      if (cells.length > 0) {
        results.push(
          <TableRow key={`row-${hour}-${rowInAnHourIdx}`}>
            {cells}
          </TableRow>
        )
      }
    }
  }
  return results
}

export function CalendarManager() {
  const classes = useStyles();
  const createKey = (date, hour, min) => `${date}_${hour}_${min}`
  const dataMap = new Map();
  const dataKeys = []
  for (let i = 0; i < data.length; i++) {
    const o = data[i];
    const key = createKey(o.date, o.startHour, o.startMin)
    dataKeys.push(key);
    dataMap.set(key, o)
  }

  const now = new Date();

  return (
    <Fragment>
      <Box display="flex" className={classes.heading}>
        <Box flexGrow={1} display="flex" alignItems="center">
          <IconButton>
            <ChevronLeft/>
          </IconButton>
          <IconButton>
            <ChevronRight/>
          </IconButton>
          <Typography variant="h5">May</Typography>
          <Typography variant="h5">2021</Typography>
        </Box>
        {/*<Box>*/}
        {/*  <FormControl variant="outlined" className={classes.formControl}>*/}
        {/*    /!*<InputLabel id="demo-simple-select-outlined-label">Age</InputLabel>*!/*/}
        {/*    <Select*/}
        {/*      InputLabelProps={{shrink: false}}*/}
        {/*      value="weekly"*/}
        {/*      // onChange={handleChange}*/}
        {/*    >*/}
        {/*      <MenuItem value="weekly">Weekly</MenuItem>*/}
        {/*      <MenuItem value="monthly">Monthly</MenuItem>*/}
        {/*    </Select>*/}
        {/*  </FormControl>*/}
        {/*</Box>*/}
      </Box>
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {
                <Fragment>
                  <TableCell>&nbsp;</TableCell>
                  {
                    Array(7).fill(0).map((_, i) => (
                      <TableCell key={firstDate + i} align="center">
                        {dayOfTheWeek[i]}
                        <div>
                          <CircularButton className={clsx({[classes.today]: firstDate + i === now.getDate()})}>
                            {firstDate + i}
                          </CircularButton>
                        </div>
                      </TableCell>
                    ))
                  }
                </Fragment>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              <Schedule initialData={data} startOfTheWeekDate={firstDate} classes={classes}/>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
}
