import React, {Fragment, useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import faker from "faker";
import {formatShortDateTime} from "../../utils/date";
import Female from "../../assets/female.png";
import ActionButton from "../hoc/ActionButton";
import BootstrapInput from "../hoc/BootstrapInput";
import {useHistory} from "react-router-dom";
import Routes from "./Routes";

const useStyles = makeStyles({
  root: {
    // width: '100%',
    backgroundColor: "transparent"
  },
  container: {
    maxHeight: "80vh",
  },
});

const StyledTable = withStyles((theme) => ({
  root: {
    borderCollapse: "separate",
    borderSpacing: "0 " + theme.spacing(3) + "px",
  },
}))(Table);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "transparent",
    color: theme.palette.common.black,
    fontWeight: "bold",
    borderColor: "transparent",
    paddingBottom: "0",
  },
  body: {
    borderColor: "transparent",
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    backgroundColor: "white",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => {
  const radius = theme.spacing(2)
  return {
    root: {
      '& > td:first-child': {
        borderTopLeftRadius: radius,
        borderBottomLeftRadius: radius,
        display: 'flex',
        alignItems: 'center',
      },
      '& > td:last-child': {
        borderTopRightRadius: radius,
        borderBottomRightRadius: radius,
      },
    },
  }
})(TableRow);

export default function PatientList() {
  const classes = useStyles();
  const history = useHistory();
  const [filter, setFilter] = useState('');
  const [listInfo, setListInfo] = useState({total: 0, rows: [], pageNo: 0, rowsPerPage: 10});
  useEffect(() => {
    handleSearch({filter, ...listInfo}).then(() => {

    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = Routes();

  const handleSearch = async ({
                                pageNo = listInfo.pageNo,
                                rowsPerPage = listInfo.rowsPerPage,
                                searchFilter = filter
                              }) => {
    const total = 50;
    const rows = [];
    for (let i = 0; i < rowsPerPage; i++) {
      rows.push({
        id: faker.datatype.uuid(),
        name: `${faker.name.lastName()}, ${faker.name.firstName()}`,
        address: "Lipata, Minglanilla, Cebu",
        phone: faker.phone.phoneNumber(),
        lastVisit: faker.date.recent(),
        nextVisit: faker.date.soon(),
      })
    }
    // const {total, rows} = result;
    setFilter(searchFilter);
    setListInfo({pageNo, rowsPerPage, total, rows});
  }

  return (
    <Fragment>
      <Box display="flex" flexDirection={"row"}>
        <Box flexGrow={1}>
          <ActionButton variant="contained" color="primary" onClick={() => history.push(routes.addPatient.path)}>
            Add New Patient
          </ActionButton>
        </Box>
        <Box mr={8}>
          <Box display="flex" alignItems="center">
            <Typography variant={"h6"}>Filter</Typography>
            <Box flexGrow={1} pl={2}>
              <FormControl>
                <Select
                  id="filter-select"
                  value=" "
                  input={<BootstrapInput/>}
                >
                  <MenuItem value=" ">A - Z</MenuItem>
                  {'abcdefghijklmnopqrstuvwxyz'.split('').map((c) => <MenuItem key={c}
                                                                               value={c}>{c.toUpperCase()}</MenuItem>)}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="flex" alignItems="center">
            <Typography variant={"h6"}>Search</Typography>
            <Box flexGrow={1} pl={2}>
              <BootstrapInput placeholder="Type something..."/>
            </Box>
          </Box>
        </Box>
      </Box>
        <TableContainer className={classes.container}>
        <StyledTable stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Last Visit</StyledTableCell>
              <StyledTableCell>Next Visit</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listInfo.rows.map((row) => {
              return (
                <StyledTableRow key={row.id}>
                  <StyledTableCell scope="row">
                    <img src={Female} height="36px" alt="Female"/>{row.name}
                  </StyledTableCell>
                  <StyledTableCell>{row.address}</StyledTableCell>
                  <StyledTableCell>{row.phone}</StyledTableCell>
                  {/*<StyledTableCell>{formatShortDateTime(row.lastVisit)}</StyledTableCell>*/}
                  <StyledTableCell>{formatShortDateTime(row.lastVisit)}</StyledTableCell>
                  <StyledTableCell>{formatShortDateTime(row.nextVisit)}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={listInfo.total}
        rowsPerPage={listInfo.rowsPerPage}
        page={listInfo.pageNo}
        onChangePage={(event, newPage) => handleSearch({pageNo: newPage})}
        onChangeRowsPerPage={(event) => handleSearch({pageNo: 0, rowsPerPage: event.target.value})}
      />
    </Fragment>
  );
}