import React from "react";
import {Dashboard as DashboardIcon, People} from "@material-ui/icons";
import Dashboard from "./Dashboard";
import PatientList from "./PatientList";
import {PatientAddEdit} from "./PatientAddEdit";
import {BookAppointment} from "./BookAppointment";

const adminRootPath = '/admin';
const patientsRootPath = `${adminRootPath}/patients`
export default function Routes() {
  return {
    pos: {
      path: `${adminRootPath}/dashboard`,
      component: <Dashboard/>,
      menuIcon: <DashboardIcon/>,
      title: `Dashboard`,
    },
    patientList: {
      path: `${patientsRootPath}/list`,
      component: <PatientList/>,
      menuIcon: <People/>,
      title: `Patients`,
    },
    bookAppointment: {
      path: `${patientsRootPath}/book-appointment`,
      component: <BookAppointment/>,
      title: `Book Appointment`,
    },
    addPatient: {
      path: `${patientsRootPath}/add`,
      component: <PatientAddEdit/>,
      title: `Add Patient`,
    },
    editPatient: {
      path: `${patientsRootPath}/edit/:id`,
      component: <PatientAddEdit/>,
      title: `Edit Patient`,
    },
  }
}