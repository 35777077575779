import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Paper, TextField} from "@material-ui/core";
import {useFormik} from "formik";
import * as yup from 'yup';
import {CalendarManager} from "./CalendarManager";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    "& > div:first-child": {
      paddingRight: theme.spacing(1),
    },
    "& > div:last-child": {
      paddingLeft: theme.spacing(1),
    },
  },
  form: {
    "& > .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
  },
  printButton: {
    marginRight: theme.spacing(1),
    "& svg": {
      height: "36px",
      width: "36px",
    }
  },
}));

export function BookAppointment() {
  const classes = useStyles();
  const validationSchema = yup.object({
    reasonForVisit: yup
      .string()
      .required('Reason for visit is required'),
    name: yup
      .string()
      .required('Name is required'),
    phoneNumber: yup
      .string()
      .required('Phone Number is required'),
    email: yup
      .string()
      .email('Enter a valid email')
  });
  const formik = useFormik({
    initialValues: {
      // email: 'foobar@example.com',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <Box component={Paper} display="flex" className={classes.root}>
      <Box width="40%">
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField fullWidth
                     label="Reason for visit"
                     name="reasonForVisit"
                     value={formik.values.reasonForVisit}
                     onChange={formik.handleChange}
                     error={formik.touched.reasonForVisit && Boolean(formik.errors.reasonForVisit)}
                     helperText={formik.touched.reasonForVisit && formik.errors.reasonForVisit}
          />
          <TextField fullWidth
                     label="Name"
                     name="name"
                     value={formik.values.name}
                     onChange={formik.handleChange}
                     error={formik.touched.name && Boolean(formik.errors.name)}
                     helperText={formik.touched.name && formik.errors.name}
          />
          <TextField fullWidth
                     label="Phone Number"
                     name="phoneNumber"
                     value={formik.values.phoneNumber}
                     onChange={formik.handleChange}
                     error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                     helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          />
          <TextField
            fullWidth
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Button fullWidth color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </form>
      </Box>
      <Box flexGrow={1}>
        <CalendarManager />
      </Box>
    </Box>
  )
}