import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, IconButton, Typography} from "@material-ui/core";
import {Print} from "@material-ui/icons";
import ActionButton from "../hoc/ActionButton";

const useStyles = makeStyles((theme) => ({
  printButton: {
    marginRight: theme.spacing(1),
    "& svg": {
      height: "36px",
      width: "36px",
    }
  },
}));

export function PatientAddEdit() {
  const classes = useStyles();
  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h6">Personal Information</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton className={classes.printButton}>
            <Print/>
          </IconButton>
          <ActionButton variant="contained" color="primary">Save</ActionButton>
        </Box>
      </Box>
    </Fragment>
  )
}