const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'full',
  timeStyle: 'long',
  timeZone: 'Asia/Manila'
});

const shortDateTimeFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: 'Asia/Manila'
});

export function formatDateTime(date) {
  if (!date) {
    return "";
  }
  const result = dateTimeFormatter.format(date); // Will return Thursday, December 20, 2012 at 11:00:00 AM GMT+8
  return result.substring(0, result.lastIndexOf(' ')); // Without GMT
}

export function formatShortDateTime(date) {
  if (!date) {
    return "";
  }
  return shortDateTimeFormatter.format(date).replaceAll(',', '')
}

export function startOfDay(date) {
  const start = date ? new Date(date) : new Date();
  start.setHours(0, 0, 0, 0);
  return start;
}

export function nextDay(date) {
  const nextDay = date ? new Date(date) : new Date();
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
}

// export function getMondayStartAndEndOfWeek(date) {
//   const d = date ? new Date(date) : new Date();
//   const day = d.getDay()
//   const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
//   return new Date(d.setDate(diff));
// }

export function getSundayStartAndEndOfWeek(date) {
  const d = date ? new Date(date) : new Date();
  const day = d.getDay()
  const diff = d.getDate() - day;
  const sunday = new Date(d.setDate(diff));
  const saturday = new Date(d.setDate(diff + 6));
  return {sunday, saturday}
}